import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ColorSelector from '../common/form/colorSeletor';
import LabelAndInputDate from '../common/form/labelAndInputDate';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Funcionario from './funcionario/funcionario';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';

import {
	setModoTela, initForm, salvar
} from './turmaActions';

class TurmaForm extends Component {

	state = {

	}

	componentDidMount() {

		const fileSelectorImagem = document.createElement('input');
		fileSelectorImagem.setAttribute('type', 'file');
		fileSelectorImagem.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;

			if (file) {

				if (!file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas imagens (.PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					let lista = (this.props.formularioValues.imagem || []).map(item => item);
					lista.push({
						imagem_base64: reader.result
					});

					this.props.initForm({
						...this.props.formularioValues,
						imagem: lista
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorImagem = fileSelectorImagem;

	}

	componentWillMount() {

	}

	render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';



		return (

			<>
				{this.props.funcionario ? (
					<>
						<ContentCard>
							<ContentCardBody>
								<Table responsive>
									<THead>
										<Tr>
											<Th >Empresa</Th>
											<Th >Profissional</Th>
											<Th >Comp.</Th>
											<Th ></Th>
										</Tr>
									</THead>
									<TBody>
										<Tr>
											<Td>{this.props.registro.nome_empresa}</Td>
											<Td>{this.props.registro.nome_usuario}</Td>
											<Td>{this.props.registro.competencia}</Td>
											<Td alignRight minWidth={100}>
												<ButtonTable
													type={'warning'}
													icon={'fas fa-arrow-left'}
													title='Voltar'
													visible={true}
													event={() => {
														this.props.setModoTela('lista', {});
														this.props.initForm({});
													}} />
												<ButtonTable
													title={`${this.props.formularioValues.finalizado ? 'Reabrir' : 'Finalizar'}`}
													type={'success'}
													icon={`${this.props.formularioValues.finalizado ? 'fas fa-undo' : 'fas fa-check'}`}
													visible={true}
													disabled={this.props.usuarioLogado && this.props.usuarioLogado.administrador ? false : this.props.formularioValues.finalizado}
													event={() => {
														this.props.salvar({
															...this.props.formularioValues,
															finalizado: !this.props.formularioValues.finalizado
														});

													}} />

											</Td>
											<Td>
											</Td>
										</Tr>
									</TBody>
								</Table>
							</ContentCardBody>
						</ContentCard>

						<Funcionario />
					</>
				) : (

					<ContentCard>
						<Form event={this.props.handleSubmit}>
							<ContentCardHeader>
								<Row alignCenter>
									<Grid cols='6 6 4 3'>
										{this.props.excluir ? (
											<Button
												text='Excluir'
												submit type={'danger'}
												icon={'fa fa-trash'} />
										) : (
											<Button
												text='Salvar'
												submit
												type={'success'}
												icon={'fa fa-check'} />
										)}
									</Grid>
									<Grid cols='6 6 4 3'>
										<Button
											text='Voltar'
											type={'warning'}
											icon={'fa fa-chevron-left'}
											event={() => this.props.setModoTela('lista')} />
									</Grid>
								</Row>

							</ContentCardHeader>
							<ContentCardBody>
								<Row>
									<Select
										name='id_empresa'
										label='Empresa' placeholder='Informe a empresa '
										cols='6 6 6 6'
										readOnly={readOnly}
										options={this.props.listaEmpresa.filter(item => this.props.usuarioLogado ? this.props.usuarioLogado.administrador || item.id_usuario == this.props.usuarioLogado.id : false)}
										value={this.props.formularioValues.id_empresa}
										onChange={itemIndex => {
											let empresa = this.props.listaEmpresa.filter(item => item.id == itemIndex)[0];
											this.props.initForm({
												...this.props.formularioValues,
												id_empresa: itemIndex,
												id_usuario: empresa ? empresa.id_usuario : null
											})
										}} />


									<Field
										name='id_usuario'
										component={Select}
										label='Profissional' placeholder='Informe o profissional'
										cols='6 6 6 6'
										readOnly={this.props.usuarioLogado && this.props.usuarioLogado.administrador ? readOnly : 'readOnly'}
										options={this.props.listaUsuario}

									/>
								</Row>


								<Row>
									<Field
										name='competencia'
										component={LabelAndInput}
										label='Competência' placeholder='Informe a competência'
										cols='6 6 6 6'
										readOnly={readOnly} />
								</Row>


								<Row>
									<Field
										name='semana1'
										component={LabelAndCheckbox}
										label=' Semana 1' placeholder='Informe a semana 1'
										cols='12 6 3 2'
										readOnly={readOnly}
									/>

									<Field
										name='semana2'
										component={LabelAndCheckbox}
										label=' Semana 2' placeholder='Informe a semana 2'
										cols='12 6 3 2'
										readOnly={readOnly} />

									<Field
										name='semana3'
										component={LabelAndCheckbox}
										label=' Semana 3' placeholder='Informe a semana 3'
										cols='12 6 3 2'
										readOnly={readOnly} />

									<Field
										name='semana4'
										component={LabelAndCheckbox}
										label=' Semana 4' placeholder='Informe a semana 4'
										cols='12 6 3 2'
										readOnly={readOnly} />

									<Field
										name='semana5'
										component={LabelAndCheckbox}
										label=' Semana 5' placeholder='Informe a semana 5'
										cols='12 6 3 2'
										readOnly={readOnly} />


								</Row>
								<Row>
									{this.props.formularioValues.semana1 ? (
										<Field
											name='data_semana1'
											component={LabelAndInputMask}
											label=' Semana 1' placeholder='Informe a data'
											cols='12 6 3 2'
											mask={'99/99/9999'}
											readOnly={readOnly}
										/>
									) : null}

									{this.props.formularioValues.semana2 ? (
										<Field
											name='data_semana2'
											component={LabelAndInputMask}
											label=' Semana 2' placeholder='Informe a data'
											cols='12 6 3 2'
											mask={'99/99/9999'}
											readOnly={readOnly} />
									) : null}

									{this.props.formularioValues.semana3 ? (
										<Field
											name='data_semana3'
											component={LabelAndInputMask}
											label=' Semana 3' placeholder='Informe a data'
											cols='12 6 3 2'
											mask={'99/99/9999'}
											readOnly={readOnly} />
									) : null}

									{this.props.formularioValues.semana4 ? (
										<Field
											name='data_semana4'
											component={LabelAndInputMask}
											label=' Semana 4' placeholder='Informe a data'
											cols='12 6 3 2'
											mask={'99/99/9999'}
											readOnly={readOnly} />
									) : null}

									{this.props.formularioValues.semana5 ? (
										<Field
											name='data_semana5'
											component={LabelAndInputMask}
											label=' Semana 5' placeholder='Informe a data'
											cols='12 6 3 2'
											mask={'99/99/9999'}
											readOnly={readOnly} />
									) : null}

								</Row>

								<Row>
							<Grid cols='12 12 12 12'>
								<label>Planilha</label>
							</Grid>
							{(this.props.formularioValues.imagem || []).map((arquivo, i) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<span class="btn btn-outline-primary fileinput-button dz-clickable">
										<img src={arquivo.imagem_base64 ? arquivo.imagem_base64 : (arquivo.imagem ? `https://api-seft.infinitum-ti.com/${arquivo.imagem}` : imagemAdd)} style={{ height: 100 }} />
									</span>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
										<button
											type='button'
											class='btn btn-flat btn-outline-primary'
											style={{ width: '48%' }}
											disabled={arquivo.imagem_base64 ?true : false}
											onClick={() => {
												window.open(`https://api-seft.infinitum-ti.com/${arquivo.imagem}`, '_blank');
											}}>
											<i class='fas fa-download'></i>
										</button>

										<button
											type='button'
											class='btn btn-flat btn-outline-danger'
											style={{ width: '48%' }}
											onClick={() => {
												this.props.initForm({
													...this.props.formularioValues,
													imagem: this.props.formularioValues.imagem.filter(item => item != arquivo)
												});
											}}>
											<i class='fas fa-trash-alt'></i>
										</button>
									</div>
								</div>
							))}

							<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorImagem.click()}>
								<img src={imagemAdd} style={{ height: 100 }} />
							</span>
						</Row>

								<br></br>

							</ContentCardBody>
							<ContentCardFooter>

							</ContentCardFooter>
						</Form>
					</ContentCard>
				)
				}
			</>



		)
	}


}

TurmaForm = reduxForm({ form: 'turmaForm', destroyOnUnmount: false })(TurmaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues('turmaForm')(state),
	registro: state.turma.registro,
	listaEmpresa: state.turma.listaEmpresa,
	listaUsuario: state.turma.listaUsuario,
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(TurmaForm);
