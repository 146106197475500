import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './cooperativaForm';
import DateFormat from '../common/dateFormat/DateFormat';

import { setModoTela, initForm, salvar, excluir, getLista} from './cooperativaActions';

class Cooperativa extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista();


    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({ });
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
				<input className='form-control'
								placeholder='Pesquisar '
								type='text'
								value={this.state.pesquisar}
								onChange={e => {
									this.setState({ ...this.state, pesquisar: e.target.value });
								}} />
					<Table responsive>
						<THead>
							<Tr>
								<Th >Nome</Th>
							
                                <Th ></Th>
							</Tr>
						</THead>
						<TBody> 
						{(this.props.lista).filter(item => {								
								if (this.state.pesquisar && !(`${item.nome} 
								`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map((item) => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
								onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
								onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
                                   
                                    <Td>{item.nome}</Td>
								
                                 
									<Td alignRight minWidth={100}>
										
                                        
                                        <ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													senha: null
												});
												this.props.initForm({
													...item,
													senha: null
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													senha: null
												});
												this.props.initForm({
													...item,
													senha: null
												});
											}} />

									</Td>
								</Tr>
							))}
						</TBody>
							
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.cooperativa,
	modoTela: state.cooperativa.modoTela,
	lista: state.cooperativa.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Cooperativa);
