const INITIAL_STATE = {
    listaPorCooperativa: [],
    listaPorEmpresa: [],
    listaPorTurma: [],
    listaPorFuncionario: [],
    listaRelatorioPresencaPorEmpresa: [],
    listaRelatorioPresencaPorCooperativa: [],
    listaPorFuncionarioQuantidadeEmpresa: []




};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'DASHBOARD_LISTADO_COOPERATIVA':

            return {
                ...state,
                modoTela: 'listaPorCooperativa',
                listaPorCooperativa: action.payload.data
            };

        case 'DASHBOARD_LISTADO_EMPRESA':

            return {
                ...state,
                modoTela: 'listaPorEmpresa',
                listaPorEmpresa: action.payload.data
            };

        case 'DASHBOARD_LISTADO_TURMA':

            return {
                ...state,
                modoTela: 'listaPorTurma',
                listaPorTurma: action.payload.data
            };

        case 'DASHBOARD_LISTADO_FUNCIONARIO':

            return {
                ...state,
                modoTela: 'listaPorFuncionario',
                listaPorFuncionario: action.payload.data
            };

        case 'FUNCIONARIO_RELATORIO_PRESENCA_POR_EMPRESA_LISTADO':
            return {
                ...state,
                listaRelatorioPresencaPorEmpresa: action.payload.data
            };

        case 'FUNCIONARIO_RELATORIO_PRESENCA_POR_COOPERATIVA_LISTADO':
            return {
                ...state,
                listaRelatorioPresencaPorCooperativa: action.payload.data
            };
       
        case 'FUNCIONARIO_QUANTIDADE_EMPRESA':
            console.log(action.payload.data);
            return {
                ...state,
                listaPorFuncionarioQuantidadeEmpresa: action.payload.data
            }; 
        default:
            return state;
    }
}
