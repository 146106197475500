import React from 'react'

export default props => (
    <td {...props} align={props.alignRight ? 'right' : (props.alignCenter ? 'center' : 'left')} onClick={props.onClick}
		style={{
			padding: 2,
			verticalAlign: 'middle',
			minWidth: props.minWidth,
			color: props.color,
			backgroundColor:
			props.backgroundColor,
			fontWeight:
			props.fontWeight,
			fontStyle: props.italic ? 'italic' : null
		}}>
		{props.children}
	</td>
)
