const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
    listaJustificado: [{
        id: true,
		valor: 'Sim'
	}, {
		id: false,
		valor: 'Não'
    }]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'MOTIVO_FALTA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'MOTIVO_FALTA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'MOTIVO_FALTA_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};
            case 'MOTIVO_FALTA_LISTADO':
                return {
                    ...state,
                    listaJustificado: action.payload.data
                };
    

        default:
            return state;
    }
}
