const INITIAL_STATE = {
    registro: {},
    modoTela: 'lista',
    aguardando: false,
    lista: [],
    listaRelatorio: [],
    listaMotivo: [],
    listaEmpresaFuncionario: []

};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'FUNCIONARIO_MODO_TELA':
            return {
                ...state,
                modoTela: action.payload.modo,
                registro: action.payload.registro
            };

        case 'FUNCIONARIO_LISTADO':
            console.log(action.payload);
            return {
                ...state,
                lista: action.payload.data
            };



        case 'FUNCIONARIO_SET_AGUARDANDO':
            return {
                ...state,
                aguardando: action.payload
            };

        case 'FUNCIONARIO_MOTIVO_FALTA_LISTADO':
            return {
                ...state,
                listaMotivo: action.payload.data
            };

        case 'FUNCIONARIO_EMPRESA_FUNCIONARIO_LISTADO':
            console.log(action.payload.data);
            return {
                ...state,
                listaEmpresaFuncionario: action.payload.data
            };


        default:
            return state;
    }
}
