const INITIAL_STATE = {
	registro: {},
	modoTela: 'listaRelatorio',
	aguardando: false,
	listaRelatorioPresencaPorCooperativa: [],
	listaRelatorioPresencaPorEmpresa: [],
	listaRelatorioPresencaPorTurma: [],
	listaCooperativa: [],
	listaEmpresa: [],
	listaTurma: [],
	filtro: {
		id_cooperativa: null,
		id_empresa: null,
		id_turma: null
	}


};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'RELATORIO_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'RELATORIO_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		case 'RELATORIO_FILTRO':
			return { ...state, filtro: action.payload };

		case 'RELATORIO_COOPERATIVA_LISTADO':
			return {
				...state,
				listaCooperativa: action.payload.data
			};

		case 'RELATORIO_EMPRESA_LISTADO':
			return {
				...state,
				listaEmpresa: action.payload.data
			};

		case 'RELATORIO_TURMA_LISTADO':
			return {
				...state,
				listaTurma: action.payload.data
			};

		case 'FUNCIONARIO_RELATORIO_PRESENCA_POR_COOPERATIVA_LISTADO':
			return {
				...state,
				listaRelatorioPresencaPorCooperativa: action.payload.data
			};

		case 'FUNCIONARIO_RELATORIO_PRESENCA_POR_EMPRESA_LISTADO':
			return {
				...state,
				listaRelatorioPresencaPorEmpresa: action.payload.data
			};

		case 'FUNCIONARIO_RELATORIO_PRESENCA_POR_TURMA_LISTADO':
			return {
				...state,
				listaRelatorioPresencaPorTurma: action.payload.data
			};

		default:
			return state;
	}

}
