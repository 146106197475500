import axios from 'axios';
import { showTabs, selectTab } from '../../common/tab/tabActions';
import consts from '../../consts';
import { setErroAPI, setSuccess } from '../../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../../common/dateFormat/DateFormat';
import FormatUtils from '../../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'EMPRESA_FUNCIONARIO_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro

			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('empresaFuncionarioForm', {
			...registro,


		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'EMPRESA_FUNCIONARIO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista(id_empresa) {
    const request = axios.get(`${consts.API_URL}/empresaFuncionario?id_empresa=${id_empresa}`);
    return {
        type: 'EMPRESA_FUNCIONARIO_LISTADO',
        payload: request
    };
}





export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/empresaFuncionario`, {
				...registro,
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_empresa));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/empresaFuncionario`, {
				...registro,
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_empresa));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}



export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/empresaFuncionario?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_empresa));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}



