import axios from 'axios';
import { showTabs, selectTab } from '../../common/tab/tabActions';
import consts from '../../consts';
import { setErroAPI, setSuccess } from '../../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../../common/dateFormat/DateFormat';
import FormatUtils from '../../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'FUNCIONARIO_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro

			}
		}
    };
}
export function initForm(registro = {}) {
	console.log(registro);
    return [
        initialize('funcionarioForm', {
			...registro,


		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'FUNCIONARIO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista(id_turma) {
    const request = axios.get(`${consts.API_URL}/funcionario?id_turma=${id_turma}`);
	
    return {
        type: 'FUNCIONARIO_LISTADO',
        payload: request
    };
}





export function salvar(registro) {
	console.log(registro);
    return (dispatch, getState) => {
		if (!registro.id) {
			axios.post(`${consts.API_URL}/empresaFuncionario`, {
				...registro,
			}).then(resp => {
				console.log(resp);
				axios.post(`${consts.API_URL}/funcionario`, {
					...registro,
					id_empresa_funcionario: resp.data.id
				}).then(resp => {
					setSuccess('Operação Realizada com sucesso.');
					dispatch(getLista(registro.id_turma));
					dispatch(setModoTela('lista'));
				}).catch(e => {
					setErroAPI(e);
				});
			}).catch(e => {
				setErroAPI(e);
			});
	       
		} else {
			axios.put(`${consts.API_URL}/funcionario`, {
				...registro,
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_turma));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function adicionar(registro) {
	console.log(registro);
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/funcionario`, {
				...registro,
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_turma));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/funcionario`, {
				...registro,
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_turma));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function salvarChamada(registro) {
    return (dispatch, getState) => {
		axios.put(`${consts.API_URL}/funcionario`, {
			...registro,
		}).then(resp => {
			dispatch(getLista(registro.id_turma));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}


export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/funcionario?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_empresa));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}



export function getListaMotivoFalta() {
    const request = axios.get(`${consts.API_URL}/motivoFalta`);
    return {
        type: 'FUNCIONARIO_MOTIVO_FALTA_LISTADO',
        payload: request
    };
}

export function getListaEmpresaFuncionario(id_empresa) {
    const request = axios.get(`${consts.API_URL}/empresaFuncionario/listarSelect?id_empresa=${id_empresa}`);
    return {
        type: 'FUNCIONARIO_EMPRESA_FUNCIONARIO_LISTADO',
        payload: request
    };
}


