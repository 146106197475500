import DateFormat from "../../common/dateFormat/DateFormat";

const INITIAL_STATE = {
    registro: {},
    modoTela: 'lista',
    aguardando: false,
    lista: [],

    

};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'EMPRESA_FUNCIONARIO_MODO_TELA':
            return {
                ...state,
                modoTela: action.payload.modo,
                registro: action.payload.registro
            };

        case 'EMPRESA_FUNCIONARIO_LISTADO':
            return {
                ...state,
                lista: action.payload.data
            };

        case 'EMPRESA_FUNCIONARIO_SET_AGUARDANDO':
            return {
                ...state,
                aguardando: action.payload
            };
        case 'EMPRESA_FUNCIONARIO_EMPRESA_LISTADO':
            return {
                ...state,
                listaEmpresa: action.payload.data
            };


        default:
            return state;
    }
}
