import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'TURMA_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro

			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('turmaForm', {
			...registro,
		})
    ];
}

export function setAguardando(aguardando) {
    return {
        type: 'TURMA_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {

	return (dispatch, getState) => {

		dispatch({
			type: 'TURMA_LISTADO',
			payload: { data: [] }
		});

		axios.get(`${consts.API_URL}/turma?
			id_empresa=${getState().turma.filtro.id_empresa || null}
			&id_usuario=${getState().turma.filtro.id_usuario || null}
			`)
			.then(resp => {
				dispatch({
					type: 'TURMA_LISTADO',
					payload: resp
				});
			}).catch(ex => {

			});
	};
}

export function salvar(registro) {

    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/turma`, {
				...registro,
				data_semana1: DateFormat.formatarDataTelaParaSql(registro.data_semana1),
				data_semana2: DateFormat.formatarDataTelaParaSql(registro.data_semana2),
				data_semana3: DateFormat.formatarDataTelaParaSql(registro.data_semana3),
				data_semana4: DateFormat.formatarDataTelaParaSql(registro.data_semana4),
				data_semana5: DateFormat.formatarDataTelaParaSql(registro.data_semana5)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/turma`, {
				...registro,
				data_semana1: DateFormat.formatarDataTelaParaSql(registro.data_semana1),
				data_semana2: DateFormat.formatarDataTelaParaSql(registro.data_semana2),
				data_semana3: DateFormat.formatarDataTelaParaSql(registro.data_semana3),
				data_semana4: DateFormat.formatarDataTelaParaSql(registro.data_semana4),
				data_semana5: DateFormat.formatarDataTelaParaSql(registro.data_semana5)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/turma?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaEmpresa() {
    const request = axios.get(`${consts.API_URL}/empresa/listarSelect`);
    return {
        type: 'TURMA_EMPRESA_LISTADO',
        payload: request
    };
}


export function getListaUsuario() {
    const request = axios.get(`${consts.API_URL}/usuario/listarSelect`);
    return {
        type: 'TURMA_USUARIO_LISTADO',
        payload: request
    };
}


export function setFiltro(filtro) {
    return {
        type: 'TURMA_FILTRO',
        payload: filtro
    };
}
