import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './empresaForm';
import DateFormat from '../common/dateFormat/DateFormat';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';



import { setModoTela, initForm, salvar, excluir, getLista, getListaUsuario, setFiltro, getListaCooperativa } from './empresaActions';

class Empresa extends Component {

	state = {
		linhaSelecionada: null
	}

	componentWillMount() {
		this.props.getLista();
		this.props.getListaUsuario();
		this.props.getListaCooperativa();



	}

	render() {
		return (
			<div>
				<Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
					{this.props.modoTela == 'empresaFuncionario' ? (
						<Form empresaFuncionario />
					) : null}
				</Content>
			</div>
		)
	}

	lista() {



		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({});
								}} />
						</Grid>

					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<Select
							name='id_usuario'
							options={this.props.listaUsuario}
							label='Profissinal'
							cols='6 6 6 6'
							placeholder='Selecione o Profissional'
							value={this.props.filtro.id_usuario}
							onChange={value => {
								this.props.setFiltro({
									...this.props.filtro,
									id_usuario: value
								});
								this.props.getLista();
							}} />

						<Select
							name='id_cooperativa'
							options={this.props.listaCooperativa}
							label='Cooperativa'
							cols='6 6 6 6'
							placeholder='Selecione o Cooperativa'
							value={this.props.filtro.id_cooperativa}
							onChange={value => {
								this.props.setFiltro({
									...this.props.filtro,
									id_cooperativa: value
								});
								this.props.getLista();
							}} />
					</Row>

					<input className='form-control'
						placeholder='Pesquisar '
						type='text'
						value={this.state.pesquisar}
						onChange={e => {
							this.setState({ ...this.state, pesquisar: e.target.value });
						}} />

					<Table responsive>
						<THead>
							<Tr>
								<Th >Nome</Th>
								<Th >CNPJ</Th>
								<Th >Profissional</Th>
								<Th >Cooperativa</Th>
								<Th >Telefone</Th>
								<Th >Contato</Th>
								<Th >Unidade</Th>
								<Th >Agência</Th>
								<Th ></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).filter(item => {

								if (this.state.pesquisar && !(`${item.nome} ${FormatUtils.formatarCNPJ(item.cnpj)} ${item.cnpj || ''}
									${FormatUtils.formatarTelefone(item.telefone)} ${item.telefone || ''} 
									${item.contato} ${item.unidade} ${item.agencia} 
								`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map((item) => (
								
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >

									<Td>{item.nome}</Td>
									<Td>{item.cnpj}</Td>
									<Td>{item.nome_usuario}</Td>
									<Td>{item.nome_cooperativa}</Td>
									<Td>{item.telefone}</Td>
									<Td>{item.contato}</Td>
									<Td>{item.unidade}</Td>
									<Td>{item.agencia}</Td>
									<Td alignRight minWidth={100}>


										<ButtonTable
											type={'success'}
											icon={'fas fa-user'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('empresaFuncionario', {
													...item,
													senha: null
												});
												this.props.initForm({
													...item,
													senha: null
												});
											}} />

										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													senha: null
												});
												this.props.initForm({
													...item,
													senha: null
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													senha: null
												});
												this.props.initForm({
													...item,
													senha: null
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>

					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.empresa,
	filtro: state.empresa.filtro,
	modoTela: state.empresa.modoTela,
	lista: state.empresa.lista,
	listaUsuario: state.empresa.listaUsuario,
	listaCooperativa: state.empresa.listaCooperativa,



});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaUsuario, setFiltro,getListaCooperativa }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Empresa);
