import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './usuarioForm';
import FormatUtils from '../common/formatUtils/FormatUtils';


import { setModoTela, initForm, salvar, excluir, getLista, setPermissao } from './usuarioActions';

class Usuario extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		let acessoUsuario = this.props.usuarioLogado && this.props.usuarioLogado.administrador;

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								disabled={!acessoUsuario}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({  usuario: true, ponto_atendimento: false, vendedor: false });
								}} />
						</Grid>
					</Row>
			
				</ContentCardHeader>
						<input className='form-control'
								placeholder='Pesquisar '
								type='text'
								value={this.state.pesquisar}
								onChange={e => {
									this.setState({ ...this.state, pesquisar: e.target.value });
								}} />
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th colspan={1}>Nome</Th>
								<Th colspan={1}>Login</Th>
								<Th colspan={1} alignCenter>Administrador</Th>
								<Th colspan={1} alignCenter>Relatório</Th>
								<Th colspan={1}></Th>
							</Tr>
						</THead>
						<TBody>
						{(this.props.lista).filter(item => {
								if (this.state.pesquisar && !(`${item.nome} ${item.login} 
								`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map((item) => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.nome}</Td>
									<Td>{item.login}</Td>
									<Td alignCenter>

										<button type='button'
											disabled={!acessoUsuario}
											title='Administrador'
											class={`btn btn-${item.administrador ? 'success' : 'default'} btn-sm`}
											onClick={() => this.props.setPermissao({ ...item, administrador: !item.administrador })}><i class={`far fa${item.administrador ? '-check' : ''}-square`}></i>
										</button>
									</Td>
									<Td alignCenter>

										<button type='button'
											disabled={!acessoUsuario}
											title='Administrador'
											class={`btn btn-${item.relatorio ? 'success' : 'default'} btn-sm`}
											onClick={() => this.props.setPermissao({ ...item, relatorio: !item.relatorio })}><i class={`far fa${item.relatorio ? '-check' : ''}-square`}></i>
										</button>
									</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={!acessoUsuario}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													senha: null
												});
												this.props.initForm({
													...item,
													senha: null
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={!acessoUsuario}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													senha: null
												});
												this.props.initForm({
													...item,
													senha: null
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.usuario.modoTela,
	lista: state.usuario.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, setPermissao }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Usuario);
