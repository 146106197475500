import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './turmaForm';
import DateFormat from '../common/dateFormat/DateFormat';
import Select from '../common/form/select';


import { setModoTela, initForm, salvar, excluir, getLista, getListaEmpresa, getListaUsuario, setFiltro } from './turmaActions';

class Turma extends Component {

	state = {
		linhaSelecionada: null
	}

	componentWillMount() {
		this.props.getLista();
		this.props.getListaEmpresa();
		this.props.getListaUsuario();



	}

	render() {
		return (
			<div>
				<Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'funcionario' ? (
						<Form funcionario />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
				</Content>
			</div>
		)
	}

	lista() {

		let bloqueado = this.props.registro.finalizado && !this.props.usuarioLogado.administrador;
		let acessoAdministrador = this.props.usuarioLogado && this.props.usuarioLogado.administrador;

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {
										id_usuario: this.props.usuarioLogado && this.props.usuarioLogado.administrador ? null : this.props.usuarioLogado.id
									});
									this.props.initForm({
										id_usuario: this.props.usuarioLogado && this.props.usuarioLogado.administrador ? null : this.props.usuarioLogado.id
									});
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<Select
							name='id_empresa'
							options={this.props.listaEmpresa}
							label='Empresa'
							cols='6 6 6 6'
							placeholder='Selecione o Empresa'
							value={this.props.filtro.id_empresa}
							onChange={value => {
								this.props.setFiltro({
									...this.props.filtro,
									id_empresa: value
								});
								this.props.getLista();
							}} />
						<Select
							name='id_usuario'
							options={this.props.listaUsuario}
							label='Profissinal'
							cols='6 6 6 6'
							placeholder='Selecione o Profissional'
							value={this.props.filtro.id_usuario}
							onChange={value => {
								this.props.setFiltro({
									...this.props.filtro,
									id_usuario: value
								});
								this.props.getLista();
							}} />
					</Row>

					<input className='form-control'
						placeholder='Pesquisar '
						type='text'
						value={this.state.pesquisar}
						onChange={e => {
							this.setState({ ...this.state, pesquisar: e.target.value });
						}} />
					<Table responsive>
						<THead>
							<Tr>
								<Th>Empresa</Th>
								<Th>Profissional</Th>
								<Th alignCenter>Comp.</Th>
								{/* <Th alignCenter>Semana 1</Th>
								<Th alignCenter>Semana 2</Th>
								<Th alignCenter>Semana 3</Th>
								<Th alignCenter>Semana 4</Th>
								<Th alignCenter>Semana 5</Th> */}
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.filter(item => {
								if (this.state.pesquisar && !(`${item.nome_empresa} ${item.nome_usuario}  ${item.competencia}
								`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								{
									if (acessoAdministrador) {
										return true;
									} else if (this.props.usuarioLogado && item.id_usuario == this.props.usuarioLogado.id) {
										return true;
									} else if (this.state.pesquisar && !(` ${item.competencia}
								`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
										return false;
									}
									return false;
								}
								return true
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.nome_empresa}</Td>
									<Td>{item.nome_usuario}</Td>
									<Td alignCenter>{item.competencia}</Td>
									{/* <Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.semana1)}</Td>
                                    <Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.semana2)}</Td>
                                    <Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.semana3)}</Td>
                                    <Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.semana4)}</Td>
                                    <Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.semana5)}</Td> */}

									{item.finalizado == true ? (
										<Td alignRight minWidth={100}>
											<ButtonTable
												type={'success'}
												icon={'fas fa-book-reader'}
												visible={this.state.linhaSelecionada == item.id}
												event={() => {
													this.props.setModoTela('funcionario', {
														...item,
														data_semana1: DateFormat.formatarDataSqlParaTela(item.data_semana1),
														data_semana2: DateFormat.formatarDataSqlParaTela(item.data_semana2),
														data_semana3: DateFormat.formatarDataSqlParaTela(item.data_semana3),
														data_semana4: DateFormat.formatarDataSqlParaTela(item.data_semana4),
														data_semana5: DateFormat.formatarDataSqlParaTela(item.data_semana5)
													});
													this.props.initForm({
														...item,
														data_semana1: DateFormat.formatarDataSqlParaTela(item.data_semana1),
														data_semana2: DateFormat.formatarDataSqlParaTela(item.data_semana2),
														data_semana3: DateFormat.formatarDataSqlParaTela(item.data_semana3),
														data_semana4: DateFormat.formatarDataSqlParaTela(item.data_semana4),
														data_semana5: DateFormat.formatarDataSqlParaTela(item.data_semana5)
													});
												}} />
										</Td>
									) : (
										<Td alignRight minWidth={100}>
											<ButtonTable
												type={'success'}
												disabled={bloqueado}

												icon={'fas fa-book-reader'}
												visible={this.state.linhaSelecionada == item.id}
												event={() => {
													this.props.setModoTela('funcionario', {
														...item,
														data_semana1: DateFormat.formatarDataSqlParaTela(item.data_semana1),
														data_semana2: DateFormat.formatarDataSqlParaTela(item.data_semana2),
														data_semana3: DateFormat.formatarDataSqlParaTela(item.data_semana3),
														data_semana4: DateFormat.formatarDataSqlParaTela(item.data_semana4),
														data_semana5: DateFormat.formatarDataSqlParaTela(item.data_semana5)
													});
													this.props.initForm({
														...item,
														data_semana1: DateFormat.formatarDataSqlParaTela(item.data_semana1),
														data_semana2: DateFormat.formatarDataSqlParaTela(item.data_semana2),
														data_semana3: DateFormat.formatarDataSqlParaTela(item.data_semana3),
														data_semana4: DateFormat.formatarDataSqlParaTela(item.data_semana4),
														data_semana5: DateFormat.formatarDataSqlParaTela(item.data_semana5)
													});
												}} />

											<ButtonTable
												type={'warning'}
												disabled={bloqueado}

												icon={'fas fa-pencil-alt'}
												visible={this.state.linhaSelecionada == item.id}
												event={() => {
													this.props.setModoTela('cadastro', {
														...item,
														data_semana1: DateFormat.formatarDataSqlParaTela(item.data_semana1),
														data_semana2: DateFormat.formatarDataSqlParaTela(item.data_semana2),
														data_semana3: DateFormat.formatarDataSqlParaTela(item.data_semana3),
														data_semana4: DateFormat.formatarDataSqlParaTela(item.data_semana4),
														data_semana5: DateFormat.formatarDataSqlParaTela(item.data_semana5)
													});
													this.props.initForm({
														...item,
														data_semana1: DateFormat.formatarDataSqlParaTela(item.data_semana1),
														data_semana2: DateFormat.formatarDataSqlParaTela(item.data_semana2),
														data_semana3: DateFormat.formatarDataSqlParaTela(item.data_semana3),
														data_semana4: DateFormat.formatarDataSqlParaTela(item.data_semana4),
														data_semana5: DateFormat.formatarDataSqlParaTela(item.data_semana5)
													});
												}} />

											<ButtonTable
												type={'danger'}
												disabled={bloqueado}

												icon={'fas fa-trash-alt'}
												visible={this.state.linhaSelecionada == item.id}
												event={() => {
													this.props.setModoTela('exclusao', {
														...item,
														data_semana1: DateFormat.formatarDataSqlParaTela(item.data_semana1),
														data_semana2: DateFormat.formatarDataSqlParaTela(item.data_semana2),
														data_semana3: DateFormat.formatarDataSqlParaTela(item.data_semana3),
														data_semana4: DateFormat.formatarDataSqlParaTela(item.data_semana4),
														data_semana5: DateFormat.formatarDataSqlParaTela(item.data_semana5),
														senha: null
													});
													this.props.initForm({
														...item,
														data_semana1: DateFormat.formatarDataSqlParaTela(item.data_semana1),
														data_semana2: DateFormat.formatarDataSqlParaTela(item.data_semana2),
														data_semana3: DateFormat.formatarDataSqlParaTela(item.data_semana3),
														data_semana4: DateFormat.formatarDataSqlParaTela(item.data_semana4),
														data_semana5: DateFormat.formatarDataSqlParaTela(item.data_semana5),
														senha: null
													});
												}} />
										</Td>
									)}
								</Tr>
							))}
						</TBody>

					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.turma.modoTela,
	lista: state.turma.lista,
	registro: state.turma.registro,
	usuarioLogado: state.auth.usuarioLogado,
	listaEmpresa: state.turma.listaEmpresa,
	listaUsuario: state.turma.listaUsuario,
	filtro: state.turma.filtro,



});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaEmpresa, getListaUsuario, setFiltro}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Turma);
