import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import Dashboard from '../dashboard/dashboard';
import Usuario from '../usuario/usuario';
import Empresa from '../empresa/empresa';
import Turma from '../turma/turma';
import Funcionario from '../turma/funcionario/funcionario'
import MotivoFalta from '../motivoFalta/motivoFalta';
import EmpresaFuncionario from '../empresa/empresaFuncionario/empresaFuncionario';
import Cooperativa from '../cooperativa/cooperativa';
import Relatorio from '../relatorio/relatorio'

export default props => (
	<div id='id-content-wrapper' className='content-wrapper' >
		<Switch>
			<Route exact path='/' component={Dashboard} />
			<Route path='/usuario' component={Usuario} />
			<Route path='/empresa' component={Empresa} />
			<Route path='/turma' component={Turma} />
			<Route path='/funcionario' component={Funcionario} />
			<Route path='/motivoFalta' component={MotivoFalta} />
			<Route path='/empresaFuncionario' component={EmpresaFuncionario} />
			<Route path='/cooperativa' component={Cooperativa} />
			<Route path='/relatorio' component={Relatorio} />
			<Redirect from='*' to='/' />
		</Switch>
	</div>
);
