import React, { Component } from 'react';
import MenuItem from './menuItem';
import MenuTree from './menuTree';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class Menu extends Component {

	render() {

		let selecionado = '';
		if (this.props.location && this.props.location.pathname) {
			selecionado = this.props.location.pathname;
		}

		let acessoAdministrador = this.props.usuarioLogado && this.props.usuarioLogado.administrador;
		let acessoRelatorio = this.props.usuarioLogado && this.props.usuarioLogado.relatorio;

		if (acessoRelatorio && selecionado != '/relatorio') {
			window.location = '/#/relatorio';
		}

		return (
			<nav className="mt-2">

				{acessoRelatorio ? (
					<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
						<MenuItem path='relatorio' label='Relatório' icon='fas fa-clipboard-list' active={selecionado == '/relatorio'} />
					</ul>
				) : (
					<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
				        <MenuItem path='/' label='Dashboard' icon='fas fa-tachometer-alt' active={selecionado == '/'} />
						{acessoAdministrador ?
							<MenuItem path='usuario' label='Usuário' icon='fas fa-users' active={selecionado == '/usuario'} />
						: null}
						{acessoAdministrador ?
							<MenuItem path='cooperativa' label='Cooperativa' icon='fas fa-city' active={selecionado == '/cooperativa'} />
						: null}
						{acessoAdministrador ?
							<MenuItem path='empresa' label='Empresa' icon='fas fa-building' active={selecionado == '/empresa'} />
						: null}
						{acessoAdministrador ?
							<MenuItem path='motivoFalta' label='Motivo Falta' icon='fas fa-tasks' active={selecionado == '/motivoFalta'} />
						: null}
						<MenuItem path='turma' label='Turma' icon='fas fa-chalkboard-teacher' active={selecionado == '/turma'} />
						<MenuItem path='relatorio' label='Relatório' icon='fas fa-clipboard-list' active={selecionado == '/relatorio'} />
					</ul>
				)}
			</nav>
		);

	}

}

const mapStateToProps = state => ({
	usuarioLogado: state.auth.usuarioLogado
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
