import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function getListaPorCooperativa() {
    const request = axios.get(`${consts.API_URL}/funcionario/listarPorCooperativa`);
    return {
        type: 'DASHBOARD_LISTADO_COOPERATIVA',
        payload: request
    };
}

export function getListaPorEmpresa() {
    const request = axios.get(`${consts.API_URL}/funcionario/listarPorEmpresa`);
    return {
        type: 'DASHBOARD_LISTADO_EMPRESA',
        payload: request
    };
}

export function getListaPorTurma() {
    const request = axios.get(`${consts.API_URL}/funcionario/listarPorTurma`);
    return {
        type: 'DASHBOARD_LISTADO_TURMA',
        payload: request
    };
}

export function getListaPorFuncionario() {
    const request = axios.get(`${consts.API_URL}/funcionario/listarPorFuncionario`);
    return {
        type: 'DASHBOARD_LISTADO_FUNCIONARIO',
        payload: request
    };
}

export function getListaRelatorioPresencaPorEmpresa() {

	return (dispatch, getState) => {

	    axios.get(`${consts.API_URL}/funcionario/listarRelatorioPresencaPorEmpresa?id_cooperativa=${getState().relatorio.filtro.id_cooperativa || null}&id_empresa=${getState().relatorio.filtro.id_empresa || null}&id_turma=${getState().relatorio.filtro.id_turma ||  null}`)
		.then(resp => {
			dispatch({
				type: 'FUNCIONARIO_RELATORIO_PRESENCA_POR_EMPRESA_LISTADO',
				payload: resp
			});
		}).catch(ex => {

		});
	}
}
export function getListaRelatorioPresencaPorCooperativa() {

	return (dispatch, getState) => {

	    axios.get(`${consts.API_URL}/funcionario/listarRelatorioPresencaPorCooperativa?id_cooperativa=${getState().relatorio.filtro.id_cooperativa || null}&id_empresa=${getState().relatorio.filtro.id_empresa || null}&id_turma=${getState().relatorio.filtro.id_turma ||  null}`)
		.then(resp => {
			dispatch({
				type: 'FUNCIONARIO_RELATORIO_PRESENCA_POR_COOPERATIVA_LISTADO',
				payload: resp
			});
		}).catch(ex => {

		});
	}
}


export function getListarPorFuncionarioQuantidadeEmpresa() {

	return (dispatch, getState) => {

	    axios.get(`${consts.API_URL}/funcionario/listarPorFuncionarioQuantidadeEmpresa`)
		.then(resp => {
			dispatch({
				type: 'FUNCIONARIO_QUANTIDADE_EMPRESA',
				payload: resp
			});
			console.log(resp.data);
		}).catch(ex => {

		});
	}
}

