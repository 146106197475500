import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import ContentCardBody from '../../common/template/contentCardBody';
import ContentCardFooter from '../../common/template/contentCardFooter';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Button from '../../common/button/button';
import Form from '../../common/form/form';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndInputNumber from '../../common/form/labelAndInputNumber';
import LabelAndInputMask from '../../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../../common/form/labelAndInputDateTime';
import Select from '../../common/form/select';
import SelectAlteracao from '../../common/form/selectAlteracao';
import LabelAndCheckbox from '../../common/form/labelAndCheckbox';
import ColorSelector from '../../common/form/colorSeletor';
import LabelAndInputDate from '../../common/form/labelAndInputDate';
import Table from '../../common/table/table';
import THead from '../../common/table/tHead';
import Tr from '../../common/table/tr';
import Th from '../../common/table/th';
import TBody from '../../common/table/tBody';
import Td from '../../common/table/td';
import ButtonTable from '../../common/table/buttonTable';


import {
	 initForm
} from './empresaFuncionarioActions';

import {
	setModoTela
} from '../../turma/funcionario/funcionarioActions';

class EmpresaFuncionarioForm extends Component {

	state = {

	}

	componentWillMount() {

	}

	render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';



		return (


			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o nome '
								cols='6 6 6 6'
								readOnly={readOnly} 
                                />

						</Row>
                        <Row>
							<Field
								name='matricula'
								component={LabelAndInput}
								label='Matrícula' placeholder='Informe a metricula '
								cols='6 6 6 6'
								readOnly={readOnly} 
                                />

						</Row>

					

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
			
		)
	}


}

EmpresaFuncionarioForm = reduxForm({ form: 'empresaFuncionarioForm', destroyOnUnmount: false })(EmpresaFuncionarioForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues('empresaFuncionarioForm')(state),
	registro: state.empresaFuncionario.registro,
 

});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EmpresaFuncionarioForm);
