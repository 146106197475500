const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaUsuario: [],
	listaCooperativa: [],
	filtro: {
		id_cooperativa: null,
		id_usuario: null,
	}


};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'EMPRESA_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'EMPRESA_LISTADO':
		console.log (action.payload.data);
			return {
				...state,
				lista: action.payload.data
			};
		case 'EMPRESA_SET_AGUARDANDO':
			return {
				...state,
				aguardando: action.payload
			};

		case 'EMPRESA_USUARIO_LISTADO':
			return {
				...state,
				listaUsuario: action.payload.data
			};

		case 'EMPRESA_COOPERATIVA_LISTADO':
			return {
				...state,
				listaCooperativa: action.payload.data
			};

		case 'EMPRESA_FILTRO':
	
			return { ...state, filtro: action.payload };


		default:
			return state;
	}
}
