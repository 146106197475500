import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from '../turma/funcionario/funcionarioForm';
import DateFormat from '../common/dateFormat/DateFormat';
import Select from '../common/form/select';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_dark from '@amcharts/amcharts4/themes/dark.js';
import locale from '@amcharts/amcharts4/lang/pt_BR.js';

import {
	setModoTela, initForm, setFiltro, getListaCooperativa, getListaEmpresa, getListaTurma,
	getListaRelatorioPresencaPorCooperativa, getListaRelatorioPresencaPorEmpresa, getListaRelatorioPresencaPorTurma
} from './relatorioActions';
import FormatUtils from '../common/formatUtils/FormatUtils';

class Relatorio extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
		this.props.getListaCooperativa();
		this.props.getListaEmpresa();
		this.props.getListaTurma();
		this.props.getListaRelatorioPresencaPorCooperativa();
		this.props.getListaRelatorioPresencaPorEmpresa();
		this.props.getListaRelatorioPresencaPorTurma();
    }

    render() {
        return (
            <div>
                <Content>
					<ContentCard>
						<ContentCardHeader>
							<Row>
								<Select
									name='id_cooperativa'
									options={this.props.listaCooperativa}
									label='Cooperativa'
									cols='12 6 3 4'
									placeholder='Selecione a cooperativa'
									value={this.props.filtro.id_cooperativa}
									onChange={value => {
										this.props.setFiltro({
											...this.props.filtro,
											id_cooperativa: value
										});
										this.props.getListaRelatorioPresencaPorCooperativa();
										this.props.getListaRelatorioPresencaPorEmpresa();
										this.props.getListaRelatorioPresencaPorTurma();
									}} />

								<Select
									name='id_cooperativa'
									options={this.props.listaEmpresa.filter(item => !this.props.filtro.id_cooperativa || item.id_cooperativa == this.props.filtro.id_cooperativa)}

									label='Empresa'
									cols='12 6 3 4'
									placeholder='Selecione a empresa'
									value={this.props.filtro.id_empresa}
									onChange={value => {
										this.props.setFiltro({
											...this.props.filtro,
											id_empresa: value
										});
										this.props.getListaRelatorioPresencaPorCooperativa();
										this.props.getListaRelatorioPresencaPorEmpresa();
										this.props.getListaRelatorioPresencaPorTurma();
									}} />

								<Select
									name='id_cooperativa'
									options={this.props.listaTurma.filter(item =>
										(!this.props.filtro.id_cooperativa || item.id_cooperativa == this.props.filtro.id_cooperativa)
										&& (!this.props.filtro.id_empresa || item.id_empresa == this.props.filtro.id_empresa)
									)}
									label='Turma'
									cols='12 6 3 4'
									placeholder='Selecione a turma'
									value={this.props.filtro.id_turma}
									onChange={value => {
										this.props.setFiltro({
											...this.props.filtro,
											id_turma: value
										});
										this.props.getListaRelatorioPresencaPorCooperativa();
										this.props.getListaRelatorioPresencaPorEmpresa();
										this.props.getListaRelatorioPresencaPorTurma();
									}} />
							</Row>
						</ContentCardHeader>
					</ContentCard>

					<ContentCard>
						<ContentCardHeader>
							<Row>
								<Grid cols='12 12 12 12'>
									<label>Participação por Cooperativa</label>
								</Grid>
							</Row>
						</ContentCardHeader>
						<ContentCardBody>
							<Table responsive>
								<THead>
									<Tr>
										<Th>Cooperativa</Th>
										<Th alignCenter>Participação</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.listaRelatorioPresencaPorCooperativa.map(item => (
										<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
											onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
											onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
											<Td>{item.nome_cooperativa}</Td>
											<Td alignCenter>{FormatUtils.formatarValorTela(item.participacao, 0)} %</Td>
										</Tr>
									))}
								</TBody>

							</Table>
							<Grid>
							
							
							<img  src={`https://quickchart.io/chart?width=590&height=220&chart={type:'bar',
										data:{labels:${JSON.stringify(this.props.listaRelatorioPresencaPorCooperativa.map(item => item.nome_cooperativa))},
										datasets:[{label:'Participação',data:${JSON.stringify(this.props.listaRelatorioPresencaPorCooperativa.map(item => item.participacao))}}]},	
										options: {legend: {labels: {fontSize: 8,}},scales: {yAxes: [{ticks: {beginAtZero: true,fontSize: 8, },},],
										xAxes: [{ ticks: {fontSize: 8,},},],},},}`}/>
							
							</Grid>
						</ContentCardBody>
					</ContentCard>

					<ContentCard>
						<ContentCardHeader>
							<Row>
								<Grid cols='12 12 12 12'>
									<label>Participação por Empresa</label>
								</Grid>
							</Row>
						</ContentCardHeader>
						<ContentCardBody>
							<Table responsive>
								<THead>
									<Tr>
										<Th>Cooperativa</Th>
										<Th>Empresa</Th>
										<Th alignCenter>Participação</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.listaRelatorioPresencaPorEmpresa.map(item => (
										<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
											onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
											onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
											<Td>{item.nome_cooperativa}</Td>
											<Td>{item.nome_empresa}</Td>
											<Td alignCenter>{FormatUtils.formatarValorTela(item.participacao, 0)} %</Td>
										</Tr>					
										))}
								</TBody>

							</Table>
							
							<Grid>
							
							
							<img  src={`https://quickchart.io/chart?width=590&height=220&chart={type:'bar',
										data:{labels:${JSON.stringify(this.props.listaRelatorioPresencaPorEmpresa.map(item => item.nome_empresa))},
										datasets:[{label:'Participação',data:${JSON.stringify(this.props.listaRelatorioPresencaPorEmpresa.map(item => item.participacao))}}]},
										options: {legend: {labels: {fontSize: 8,}},scales: {yAxes: [{ticks: {beginAtZero: true,fontSize: 8, },},											  ],
										xAxes: [{ ticks: {fontSize: 8,},},],},},}`}/>
							
							</Grid>
					</ContentCardBody>
					
					</ContentCard>

					<ContentCard>
						<ContentCardHeader>
							<Row>
								<Grid cols='12 12 12 12'>
									<label>Participação por Turma</label>
								</Grid>
							</Row>
						</ContentCardHeader>
						<ContentCardBody>
							<Table responsive>
								<THead>
									<Tr>
										<Th>Cooperativa</Th>
										<Th>Empresa</Th>
										<Th>Turma</Th>
										<Th alignCenter>Participação</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.listaRelatorioPresencaPorTurma.map(item => (
										<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
											onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
											onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
											<Td>{item.nome_cooperativa}</Td>
											<Td>{item.nome_empresa}</Td>
											<Td>{item.competencia}</Td>
											<Td alignCenter>{FormatUtils.formatarValorTela(item.participacao, 0)} %</Td>
										</Tr>
									))}
								</TBody>

							</Table>
							<img  src={`https://quickchart.io/chart?&width=590&height=220&fontsize=5&chart={type:'bar',
										data:{labels:${JSON.stringify(this.props.listaRelatorioPresencaPorTurma.map(item => item.competencia))},
										datasets:[{label:'Participação',
										data:${JSON.stringify(this.props.listaRelatorioPresencaPorTurma.map(item => item.participacao))}}]},   
										options: {legend: {labels: {fontSize: 8,}},scales: {yAxes: [{ticks: {beginAtZero: true,fontSize: 8, },},											  ],
											  xAxes: [{ ticks: {fontSize: 8,},},],},},}`}/>
						</ContentCardBody>
					</ContentCard>
                </Content>
            </div>
        )
    }


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.relatorio.modoTela,
	filtro: state.relatorio.filtro,
	listaCooperativa: state.relatorio.listaCooperativa,
	listaEmpresa: state.relatorio.listaEmpresa,
	listaTurma: state.relatorio.listaTurma,
	listaRelatorioPresencaPorCooperativa: state.relatorio.listaRelatorioPresencaPorCooperativa,
	listaRelatorioPresencaPorEmpresa: state.relatorio.listaRelatorioPresencaPorEmpresa,
	listaRelatorioPresencaPorTurma: state.relatorio.listaRelatorioPresencaPorTurma
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, setFiltro, getListaCooperativa, getListaEmpresa, getListaTurma,
	getListaRelatorioPresencaPorCooperativa, getListaRelatorioPresencaPorEmpresa, getListaRelatorioPresencaPorTurma
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Relatorio);


