import { formValues, formValueSelector } from "redux-form";

const INITIAL_STATE = {
    registro: {},
    modoTela: 'lista',
    aguardando: false,
    lista: [],
    listaEmpresa: [],
    listaUsuario: [],
    filtro: {
		id_empresa: null,
		id_usuario: null,
	}


};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'TURMA_MODO_TELA':
            return {
                ...state,
                modoTela: action.payload.modo,
                registro: action.payload.registro
            };

        case 'TURMA_LISTADO':
            return {
                ...state,
                lista: action.payload.data
            };

        case 'TURMA_SET_AGUARDANDO':
            return {
                ...state,
                aguardando: action.payload
            };

        case 'TURMA_EMPRESA_LISTADO':
            return {
                ...state,
                listaEmpresa: action.payload.data
            };

        case 'TURMA_USUARIO_LISTADO':
            return {
                ...state,
                listaUsuario: action.payload.data
            };

        case 'TURMA_FILTRO':

            return { ...state, filtro: action.payload };



        default:
            return state;
    }
}
