import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'RELATORIO_MODO_TELA',
        payload: {
			modo: modo,
			registro: {
				...registro

			}
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('funcionarioForm', {
			...registro,


		})
    ];
}

export function setFiltro(filtro) {
    return {
        type: 'RELATORIO_FILTRO',
        payload: filtro
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'RELATORIO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getListaCooperativa() {
    const request = axios.get(`${consts.API_URL}/cooperativa/listarSelect`);
    return {
        type: 'RELATORIO_COOPERATIVA_LISTADO',
        payload: request
    };
}

export function getListaEmpresa() {
    const request = axios.get(`${consts.API_URL}/empresa/listarSelect`);
    return {
        type: 'RELATORIO_EMPRESA_LISTADO',
        payload: request
    };
}

export function getListaTurma() {
    const request = axios.get(`${consts.API_URL}/turma/listarSelect`);
    return {
        type: 'RELATORIO_TURMA_LISTADO',
        payload: request
    };
}

export function getListaRelatorioPresencaPorCooperativa() {

	return (dispatch, getState) => {

	    axios.get(`${consts.API_URL}/funcionario/listarRelatorioPresencaPorCooperativa?id_cooperativa=${getState().relatorio.filtro.id_cooperativa || null}&id_empresa=${getState().relatorio.filtro.id_empresa || null}&id_turma=${getState().relatorio.filtro.id_turma ||  null}`)
		.then(resp => {
			dispatch({
				type: 'FUNCIONARIO_RELATORIO_PRESENCA_POR_COOPERATIVA_LISTADO',
				payload: resp
			});
		}).catch(ex => {

		});
	}
}

export function getListaRelatorioPresencaPorEmpresa() {

	return (dispatch, getState) => {

	    axios.get(`${consts.API_URL}/funcionario/listarRelatorioPresencaPorEmpresa?id_cooperativa=${getState().relatorio.filtro.id_cooperativa || null}&id_empresa=${getState().relatorio.filtro.id_empresa || null}&id_turma=${getState().relatorio.filtro.id_turma ||  null}`)
		.then(resp => {
			dispatch({
				type: 'FUNCIONARIO_RELATORIO_PRESENCA_POR_EMPRESA_LISTADO',
				payload: resp
			});
		}).catch(ex => {

		});
	}
}

export function getListaRelatorioPresencaPorTurma() {

	return (dispatch, getState) => {

	    axios.get(`${consts.API_URL}/funcionario/listarRelatorioPresencaPorTurma?id_cooperativa=${getState().relatorio.filtro.id_cooperativa || null}&id_empresa=${getState().relatorio.filtro.id_empresa || null}&id_turma=${getState().relatorio.filtro.id_turma ||  null}`)
		.then(resp => {
			dispatch({
				type: 'FUNCIONARIO_RELATORIO_PRESENCA_POR_TURMA_LISTADO',
				payload: resp
			});
		}).catch(ex => {

		});
	}
}
