import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { } from './dashboardActions';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from '../common/widget/valueBox'
import Row from '../common/layout/row'
import InfoBox from '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import Card from './layout/Card'
import FormatUtils from '../common/formatUtils/FormatUtils';


import imagemUser from '../assets/images/user.jpg';

import { getListaPorCooperativa, getListaPorEmpresa, getListaPorTurma, getListaPorFuncionario, getListaRelatorioPresencaPorEmpresa, getListaRelatorioPresencaPorCooperativa,
    getListarPorFuncionarioQuantidadeEmpresa } from './dashboardActions';
import { arrayRemoveAll, arrayUnshift } from 'redux-form';

class Dashboard extends Component {

    state = {

    }

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getListaPorCooperativa();
        this.props.getListaPorTurma();
        this.props.getListaPorEmpresa();
        this.props.getListaPorFuncionario();
        this.props.getListaRelatorioPresencaPorEmpresa();
        this.props.getListaRelatorioPresencaPorCooperativa();
        this.props.getListarPorFuncionarioQuantidadeEmpresa();

 



    }

    render() {
        const colors2 = ['#a2b9bc', '#b2ad7f', '#878f99' , '#6b5b95', '#d64161', '#2ab0c5', '#39c0b3']
        const colors = ['#e98931', '#eb403b', '#b32E37' , '#6c2a6a', '#5c4399', '#274389', '#1f5ea8', '#227FB0', '#2ab0c5', '#39c0b3']
        for(let i = 0; i < colors.length; i = i + 1 )
        for(let i2 = 0; i2 < colors2.length; i2 = i2 + 1 )


        return (
            <Content>

                <Row>

                    <Grid style={{ marginLeft: 15 }}>

                        <h3>
                            Dashboard
                        </h3>
                    </Grid>



                </Row>
                <div class="col-lg-12 col-12" >
                    <div class="small-box bg-info"  >  
                         <div class="inner" style={{ background: '#fff', color: '#fff' }}>
                
                <Row  >
                    {this.props.listaPorCooperativa.map(item => (


                        <div class="col-lg-3 col-5" >

                            <div class="small-box bg-info"  >
                                <div class="inner" style={{ background: '#8AC926', color: '#fff' }}>
                                    <h3>{item.quantidade}</h3>
                                    Cooperativa
                                </div>

                            </div>
                        </div>
                    ))}

                    {this.props.listaPorEmpresa.map(item => (
                        <div class="col-lg-3 col-5" >

                            <div class="small-box bg-info"  >
                                <div class="inner" style={{ background: '#FFCA3A', color: '#fff' }}>
                                    <h3>{item.quantidade}</h3>
                                    Empresa
                                </div>

                            </div>
                        </div>
                    ))}

                    {this.props.listaPorTurma.map(item => (
                        <div class="col-lg-3 col-5" >

                            <div class="small-box bg-info"  >
                                <div class="inner" style={{ background: '#1982C4', color: '#fff' }}>
                                    <h3>{item.quantidade}</h3>
                                    Turma
                                </div>

                            </div>
                        </div>
                    ))}

                    {this.props.listaPorFuncionario.map(item => (
                        <div class="col-lg-3 col-5" >

                            <div class="small-box bg-info"  >
                                <div class="inner" style={{ background: '#fb8500', color: '#fff' }}>
                                    <h3>{item.quantidade}</h3>
                                    Funcionário
                                </div>

                            </div>
                        </div>
                    ))}
                </Row>
                        </div>                  
                    </div>
                </div>
                <div class="col-lg-12 col-12" >
                    <label>
                    Participação por Cooperativa
                    </label>
                    <div class="small-box bg-info"  >  
                         <div class="inner" style={{ background: '#fff', color: '#fff' }}>
                         <Row>
                                {this.props.listaRelatorioPresencaPorCooperativa.map(item => (
                                    <div class="col-lg-3 col-5" >
                                        <div class="small-box bg-info"  >
                                            <div class="inner" style={{ background: ('[for]', colors2[i2= i2 + 1]), color: '#fff' }}>
                                                <h3>{item.participacao}%</h3>
                                                {item.nome_cooperativa}
                                            </div>
                                            
                                        </div>
                                    </div>



                                ))}
                            </Row>
                            
                        </div>                  
                   
                    </div>
                </div>
                <div class="col-lg-12 col-12" >
                    <label>
                    Participação por Empresa
                    </label>
                    <div class="small-box bg-info"  >  
                         <div class="inner" style={{ background: '#fff', color: '#fff' }}>
                         <Row>
                                {this.props.listaRelatorioPresencaPorEmpresa.map(item => (
                                    <div class="col-lg-3 col-5" >
                                        <div class="small-box bg-info"  >
                                            <div class="inner" style={{ background:('[for]', colors[i= i + 1]), color: '#fff' }}>
                                                <h3>{item.participacao}%</h3>
                                                {item.nome_empresa}
                                            </div>
                                        </div>
                                    </div>
                                 ))}
                            </Row>

                            
                        </div>                  
                   
                    </div>
                </div>
                <ContentCard>
						<ContentCardHeader>
							<Row>
								<Grid cols='12 12 12 12'>
									<label>Participação por Cooperativa</label>
								</Grid>
							</Row>
						</ContentCardHeader>
						<ContentCardBody>
							<Grid>						
							<img  src={`https://quickchart.io/chart?width=590&height=220&chart={type:'bar',
										data:{labels:${JSON.stringify(this.props.listaRelatorioPresencaPorCooperativa.map(item => item.nome_cooperativa))},
										datasets:[{label:'Participação',data:${JSON.stringify(this.props.listaRelatorioPresencaPorCooperativa.map(item => item.participacao))},backgroundColor:['rgba(178, 173, 127)','rgba(135, 143, 153)','rgba(107, 91, 149)','rgba(214, 65, 97)','rgba(	42, 176, 197)','rgba(	57, 192, 179)']}]},	
									    options: {legend: {labels: {fontSize: 8,}},scales: {yAxes: [{ticks: {beginAtZero: true,fontSize: 8, },},],
										xAxes: [{ ticks: {fontSize: 8,},},],},},}`}/>
							</Grid>
						</ContentCardBody>
					</ContentCard>
                    <ContentCard>
						<ContentCardHeader>
							<Row>
								<Grid cols='12 12 12 12'>
									<label>Participação por Empresa</label>
								</Grid>
							</Row>
						</ContentCardHeader>
						<ContentCardBody>
							<Grid>
							<img  src={`https://quickchart.io/chart?width=590&height=220&chart={type:'bar',
										data:{labels:${JSON.stringify(this.props.listaRelatorioPresencaPorEmpresa.map(item => item.nome_empresa))},
										datasets:[{label:'Participação',data:${JSON.stringify(this.props.listaRelatorioPresencaPorEmpresa.map(item => item.participacao))},backgroundColor:['rgba(235, 64, 59)','rgba(179, 46, 55)','rgba(108, 42, 106)','rgba(92, 67, 153)','rgba(39, 67, 137)','rgba(31, 94, 168)','rgba(34, 127, 176)']}]},
										options: {legend: {labels: {fontSize: 8,}},scales: {yAxes: [{ticks: {beginAtZero: true,fontSize: 8, },},],
										xAxes: [{ ticks: {fontSize: 8,},},],},},}`}/>
							
							</Grid>
					</ContentCardBody>
					
					</ContentCard>
                    <ContentCard>
						<ContentCardHeader>
							<Row>
								<Grid cols='12 12 12 12'>
									<label>Funcionario por Empresa</label>
								</Grid>
							</Row>
						</ContentCardHeader>
						<ContentCardBody>
							<Grid>
							<img  src={`https://quickchart.io/chart?width=590&height=220&chart={type:'bar',
										data:{labels:${JSON.stringify(this.props.listaPorFuncionarioQuantidadeEmpresa.map(item => item.nome_empresa))},
										datasets:[{label:'Funcionario',data:${JSON.stringify(this.props.listaPorFuncionarioQuantidadeEmpresa.map(item => item.quantidade))},backgroundColor:['rgba(251, 133, 0)']}]},
                                        options: {legend: {labels: {fontSize: 8,}},scales: {yAxes: [{ticks: {beginAtZero: true,fontSize: 8, },},],
										xAxes: [{ ticks: {fontSize: 6,},},],},},}`}/>
							
							</Grid>
					</ContentCardBody>
					
					</ContentCard>
                    
            </Content> 
       
            					
                                    
                              
        )
    }
}

const mapStateToProps = state => ({
    sessao: state.auth.usuario,
    listaPorCooperativa: state.dashboard.listaPorCooperativa,
    listaPorFuncionario: state.dashboard.listaPorFuncionario,
    listaPorTurma: state.dashboard.listaPorTurma,
    listaPorEmpresa: state.dashboard.listaPorEmpresa,
	listaRelatorioPresencaPorEmpresa: state.dashboard.listaRelatorioPresencaPorEmpresa,
    listaRelatorioPresencaPorCooperativa: state.dashboard.listaRelatorioPresencaPorCooperativa,
    listaCores:state.dashboard.listaCores,
    listaPorFuncionarioQuantidadeEmpresa: state.dashboard.listaPorFuncionarioQuantidadeEmpresa




});
const mapDispatchToProps = dispatch => bindActionCreators({
    getListaPorCooperativa, getListaPorEmpresa, getListaPorTurma, getListaPorFuncionario, getListaRelatorioPresencaPorEmpresa, getListaRelatorioPresencaPorCooperativa,
    getListarPorFuncionarioQuantidadeEmpresa
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)

