import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
	return {
		type: 'EMPRESA_MODO_TELA',
		payload: {
			modo: modo,
			registro: {
				...registro

			}
		}
	};
}
export function initForm(registro = {}) {
	return [
		initialize('empresaForm', {
			...registro,


		})
	];
}

export function setAguardando(aguardando) {
	return {
		type: 'EMPRESA_SET_AGUARDANDO',
		payload: aguardando
	};
}

export function getLista() {

	return (dispatch, getState) => {

		dispatch({
			type: 'EMPRESA_LISTADO',
			payload: { data: [] }
		});

		axios.get(`${consts.API_URL}/empresa?
			id_cooperativa=${getState().empresa.filtro.id_cooperativa || null}
			&id_usuario=${getState().empresa.filtro.id_usuario || null}
			`)
			.then(resp => {
				dispatch({
					type: 'EMPRESA_LISTADO',
					payload: resp
				});
			}).catch(ex => {

			});
	};
}




export function salvar(registro) {
	return (dispatch, getState) => {
		if (!registro.id) {
			axios.post(`${consts.API_URL}/empresa`, {
				...registro,

			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/empresa`, {
				...registro,

			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
	};
}

export function excluir(registro) {
	return dispatch => {
		axios.delete(`${consts.API_URL}/empresa?id=${registro.id}`)
			.then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
	};
}

export function setFiltro(filtro) {
	return {
		type: 'EMPRESA_FILTRO',
		payload: filtro
	};
}


export function getListaUsuario() {
	const request = axios.get(`${consts.API_URL}/usuario/listarSelect`);
	return {
		type: 'EMPRESA_USUARIO_LISTADO',
		payload: request
	};
}

export function getListaCooperativa() {
	const request = axios.get(`${consts.API_URL}/cooperativa/listarSelect`);
	return {
		type: 'EMPRESA_COOPERATIVA_LISTADO',
		payload: request
	};
}