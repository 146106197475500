import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioReducer from '../usuario/usuarioReducer';
import EmpresaReducer from '../empresa/empresaReducer';
import TurmaReducer from '../turma/turmaReducer';
import MotivoFaltaReducer from '../motivoFalta/motivoFaltaReducer';
import FuncionarioReducer from '../turma/funcionario/funcionarioReducer';
import EmpresaFuncionarioReducer from '../empresa/empresaFuncionario/empresaFuncionarioReducer'
import CooperativaReducer from '../cooperativa/cooperativaReducer';
import RelatorioRducer from '../relatorio/relatorioReducer'

const rootReducer = combineReducers({
	auth: AuthReducer,
    dashboard: DashboardReducer,
    usuario: UsuarioReducer,
    empresa: EmpresaReducer,
    turma: TurmaReducer,
    motivoFalta:MotivoFaltaReducer,
    funcionario:FuncionarioReducer,
    empresaFuncionario:EmpresaFuncionarioReducer,
    cooperativa:CooperativaReducer,
    relatorio:RelatorioRducer,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;
