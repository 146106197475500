import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ColorSelector from '../common/form/colorSeletor';
import LabelAndInputDate from '../common/form/labelAndInputDate';
import EmpresaFuncionario from './empresaFuncionario/empresaFuncionario';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';

import {
	setModoTela, initForm
} from './empresaActions';

class EmpresaForm extends Component {

	state = {

	}

	componentWillMount() {

	}

	render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';



		return (
			<>
				{this.props.empresaFuncionario ? (
					<>
						<ContentCard>
							<ContentCardBody>
								<Table responsive>
									<THead>
										<Tr>
											<Th >Empresa</Th>

											<Th ></Th>
										</Tr>
									</THead>
									<TBody>
										<Tr>
											<Td>{this.props.registro.nome}</Td>

											<Td alignRight minWidth={100}>
												<ButtonTable
													type={'warning'}
													icon={'fas fa-arrow-left'}
													title='Voltar'
													visible={true}
													event={() => {
														this.props.setModoTela('lista', {});
														this.props.initForm({});
													}} />
											</Td>
										</Tr>
									</TBody>
								</Table>
							</ContentCardBody>
						</ContentCard>

						<EmpresaFuncionario />
					</>


				) :

					<ContentCard>
						<Form event={this.props.handleSubmit}>
							<ContentCardBody>
								<Row>
									<Field
										name='nome'
										component={LabelAndInput}
										label='Nome' placeholder='Informe o nome '
										cols='6 6 6 6'
										readOnly={readOnly} />

								</Row>

								<Row>

									<Field
										name='cnpj'
										component={LabelAndInputMask}
										mask='99999999999999'
										label='CNPJ' placeholder='Informe o cnpj'
										cols='12 6 4 3'
										readOnly={readOnly}
									/>
									<Field
										name='telefone'
										component={LabelAndInputMask}
										label='Telefone' placeholder='Informe o telefone'
										mask='(99) 99999-9999'
										cols='12 6 4 3'
										readOnly={readOnly} />
								</Row>
								<Row>

									<Field
										name='id_usuario'
										component={Select}
										label='Profissional' placeholder='Informe o profissional'
										cols='12 6 4 3'
										readOnly={readOnly}
										options={this.props.listaUsuario}
									/>
									<Field
										name='id_cooperativa'
										component={Select}
										label='Cooperativa' placeholder='Informe a cooperativa'
										cols='12 6 4 3'
										readOnly={readOnly}
										options={this.props.listaCooperativa}
									/>
								</Row>
								<Row>
									<Field
										name='contato'
										component={LabelAndInput}
										label='Contato' placeholder='Informe o contato'
										cols='6 6 6 6'
										readOnly={readOnly} />
								</Row>
								<Row>
									<Field
										name='unidade'
										component={LabelAndInputNumber}
										label='Unidade' placeholder='Informe a unidade'
										cols='12 6 4 3'
										readOnly={readOnly}
									/>

									<Field
										name='agencia'
										component={LabelAndInputNumber}
										label='Agência' placeholder='Informe a agência'
										cols='12 6 4 3'
										readOnly={readOnly}
									/>
								</Row>

							</ContentCardBody>
							<ContentCardFooter>
								<Row alignCenter>
									<Grid cols='6 6 4 3'>
										{this.props.excluir ? (
											<Button
												text='Excluir'
												submit type={'danger'}
												icon={'fa fa-trash'} />
										) : (
											<Button
												text='Salvar'
												submit
												type={'success'}
												icon={'fa fa-check'} />
										)}
									</Grid>
									<Grid cols='6 6 4 3'>
										<Button
											text='Voltar'
											type={'warning'}
											icon={'fa fa-chevron-left'}
											event={() => this.props.setModoTela('lista')} />
									</Grid>
								</Row>
							</ContentCardFooter>
						</Form>
					</ContentCard>
				}
			</>
		)
	}


}

EmpresaForm = reduxForm({ form: 'empresaForm', destroyOnUnmount: false })(EmpresaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues('empresaForm')(state),
	registro: state.empresa.registro,
	listaUsuario: state.empresa.listaUsuario,
	listaCooperativa: state.empresa.listaCooperativa,

});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EmpresaForm);
