import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import ContentCardBody from '../../common/template/contentCardBody';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Table from '../../common/table/table';
import THead from '../../common/table/tHead';
import Tr from '../../common/table/tr';
import Th from '../../common/table/th';
import TBody from '../../common/table/tBody';
import Td from '../../common/table/td';
import ButtonTable from '../../common/table/buttonTable';
import Button from '../../common/button/button';
import Form from './funcionarioForm';
import DateFormat from '../../common/dateFormat/DateFormat';
import Select from '../../common/form/select';
import FormI from '../../empresa/empresaFuncionario/empresaFuncionarioForm';


import { setModoTela, adicionar, salvar, excluir, salvarChamada, getLista, getListaMotivoFalta, getListaEmpresaFuncionario } from './funcionarioActions';
import { setModoTela as setModoTelaTurma } from '../turmaActions';
import { initForm } from '../../empresa/empresaFuncionario/empresaFuncionarioActions';


class Funcionario extends Component {

	state = {
		linhaSelecionada: null
	}

	componentWillMount() {
		this.props.getLista(this.props.registro.id);
		this.props.getListaMotivoFalta();
		this.props.getListaEmpresaFuncionario(this.props.registro.id_empresa)
	}

	render() {
		return (
			<div>
				<Content>
					{this.props.modoTela == 'cadastroI' ? (
						<FormI onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<FormI excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
					{this.props.modoTela == 'chamada' ? this.listaChamada() : null}



				</Content>
			</div>
		)
	}

	lista() {

		let bloqueado = this.props.registro.finalizado && !this.props.usuarioLogado.administrador;

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Select
								readOnly={bloqueado ? "readOnly" : null}
								name='id_empresa_funcionario'
								label='Funcionario' placeholder='Informe o funcionario '
								cols='12 12 12 12'
								options={this.props.listaEmpresaFuncionario}
								onChange={item => {
									if (item) {
										this.props.adicionar({
											id_turma: this.props.registro.id, id_empresa: this.props.registro.id_empresa, id_empresa_funcionario: item,
										})
									}

								}}
							/>
						</Grid>
						<Row>
						<div class="col-lg-12 col-12" >
                    <label>
                    
                    </label>
                    {/* <div class="small-box bg-info"  >  
                         <div class="inner" style={{ background: '#fff', color: '#fff' }}>
                         <Row>
                                {this.props.listaRelatorioPresencaPorCooperativa.map(item => (
                                    <div class="col-lg-3 col-5" >
                                        <div class="small-box bg-info"  >
                                            <div class="inner" style={{ background: '#' + Math.floor(Math.random() * 0x1000000).toString(16).padStart(6, '0'), color: '#fff' }}>
                                                <h3>{item.participacao}%</h3>
                                    
                                            </div>
                                            
                                        </div>
                                    </div>



                                ))}
                            </Row>
                            
                        </div>                  
                   
                    </div> */}
                </div>
						</Row>
						<Grid cols='6 6 4 2' >

							<Button
								disabled={bloqueado}
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									console.log(this.props.registro);
									this.props.setModoTela('cadastroI', { id_empresa: this.props.registro.id_empresa, id_turma: this.props.registro.id });
									this.props.initForm({ id_empresa: this.props.registro.id_empresa, id_turma: this.props.registro.id });
								}} />

						</Grid>
						{this.props.registro.semana1 ? (
							<Grid cols='6 4 2 1'>
								<Button
									disabled={bloqueado}
									text={this.props.registro.data_semana1 ? this.props.registro.data_semana1 : 'Semana 1'}
									type={'primary'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.setModoTela('chamada');
										this.props.setModoTelaTurma('funcionario', { ...this.props.registro, chamada: 1, chamadaData: this.props.registro.semana1 || 'Semana 1' });
									}} />
							</Grid>
						) : null
						}
						{this.props.registro.semana2 ? (
							<Grid cols='6 4 2 1'>
								<Button
									disabled={bloqueado}
									text={this.props.registro.data_semana2 ? this.props.registro.data_semana2 : 'Semana 2'}
									type={'primary'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.setModoTela('chamada');
										this.props.setModoTelaTurma('funcionario', { ...this.props.registro, chamada: 2, chamadaData: this.props.registro.semana2 || 'Semana 2' });
									}} />
							</Grid>
						) : null
						}
						{this.props.registro.semana3 ? (
							<Grid cols='6 4 2 1'>
								<Button
									disabled={bloqueado}
									text={this.props.registro.data_semana3 ? this.props.registro.data_semana3 : 'Semana 3'}
									type={'primary'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.setModoTela('chamada');
										this.props.setModoTelaTurma('funcionario', { ...this.props.registro, chamada: 3, chamadaData: this.props.registro.semana3 || 'Semana 3' });
									}} />
							</Grid>
						) : null
						}
						{this.props.registro.semana4 ? (
							<Grid cols='6 4 2 1'>
								<Button
									disabled={bloqueado}
									text={this.props.registro.data_semana4 ? this.props.registro.data_semana4 : 'Semana 4'}
									type={'primary'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.setModoTela('chamada');
										this.props.setModoTelaTurma('funcionario', { ...this.props.registro, chamada: 4, chamadaData: this.props.registro.semana4 || 'Semana 4' });
									}} />
							</Grid>
						) : null
						}
						{this.props.registro.semana5 ? (
							<Grid cols='6 4 2 1'>
								<Button
									disabled={bloqueado}
									text={this.props.registro.data_semana5 ? this.props.registro.data_semana5 : 'Semana 5'}
									type={'primary'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.setModoTela('chamada');
										this.props.setModoTelaTurma('funcionario', { ...this.props.registro, chamada: 5, chamadaData: this.props.registro.semana5 || 'Semana 5' });
									}} />
							</Grid>
						) : null
						}

					</Row>
				</ContentCardHeader>
				<ContentCardBody>


					<Table responsive>
						<THead>
							<Tr>
								<Th>Nome</Th>
								<Th alignCenter>Presença</Th>
								<Th alignCenter>Justif.</Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >

									<Td>{item.matricula} - {item.nome_empresa_funcionario}</Td>
									<Td alignCenter>{(item.presenca_semana1 ? 1 : 0) + (item.presenca_semana2 ? 1 : 0) + (item.presenca_semana3 ? 1 : 0) + (item.presenca_semana4 ? 1 : 0) + (item.presenca_semana5 ? 1 : 0)}</Td>
									<Td alignCenter>{(item.justificada1 ? 1 : 0) + (item.justificada2 ? 1 : 0) + (item.justificada3 ? 1 : 0) + (item.justificada4 ? 1 : 0) + (item.justificada5 ? 1 : 0)}</Td>
									<Td alignRight minWidth={50}>
										<ButtonTable
											disabled={bloqueado}
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													senha: null
												});
												this.props.initForm({
													...item,
													senha: null
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>

					</Table>

				</ContentCardBody>
			</ContentCard>
		);
	}
	listaChamada() {



		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>

							<Button
								text='Voltar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('lista',);
									this.props.initForm();
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>

					<Table responsive>
						<THead>
							<Tr>
								<Th>Nome Funcionario </Th>
								<Th alignCenter>{this.props.registro.chamadaData}</Th>
								<Th alignCenter>Motivo</Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.nome_empresa_funcionario}</Td>
									<Td alignCenter>
										{this.props.registro.chamada == 1 ? (
											<input type='checkbox' checked={item.presenca_semana1} onChange={() => this.props.salvarChamada({ ...item, presenca_semana1: !item.presenca_semana1 })} />
										) : null}
										{this.props.registro.chamada == 2 ? (
											<input type='checkbox' checked={item.presenca_semana2} onChange={() => this.props.salvarChamada({ ...item, presenca_semana2: !item.presenca_semana2 })} />
										) : null}
										{this.props.registro.chamada == 3 ? (
											<input type='checkbox' checked={item.presenca_semana3} onChange={() => this.props.salvarChamada({ ...item, presenca_semana3: !item.presenca_semana3 })} />
										) : null}
										{this.props.registro.chamada == 4 ? (
											<input type='checkbox' checked={item.presenca_semana4} onChange={() => this.props.salvarChamada({ ...item, presenca_semana4: !item.presenca_semana4 })} />
										) : null}
										{this.props.registro.chamada == 5 ? (
											<input type='checkbox' checked={item.presenca_semana5} onChange={() => this.props.salvarChamada({ ...item, presenca_semana5: !item.presenca_semana5 })} />
										) : null}
									</Td>
									<Td alignCenter>
										{this.props.registro.chamada == 1 ? (
											<select
												className='form-control'
												disabled={item.presenca_semana1}
												value={item.id_motivo_semana1 || 0}
												onChange={(data, value) => {
													this.props.salvarChamada({ ...item, id_motivo_semana1: parseInt(data.target.value || 0) > 0 ? data.target.value : null });
												}}>
												<option value='0'>Selecione</option>
												{this.props.listaMotivo.map(opt => {
													return (
														<option key={opt.id} value={opt.id}>{opt.nome}</option>
													);
												})}
											</select>
										) : null}
										{this.props.registro.chamada == 2 ? (
											<select
												className='form-control'
												disabled={item.presenca_semana2}
												value={item.id_motivo_semana2 || 0}
												onChange={(data, value) => {
													this.props.salvarChamada({ ...item, id_motivo_semana2: parseInt(data.target.value || 0) > 0 ? data.target.value : null });
												}}>
												<option value='0'>Selecione</option>
												{this.props.listaMotivo.map(opt => {
													return (
														<option key={opt.id} value={opt.id}>{opt.nome}</option>
													);
												})}
											</select>
										) : null}
										{this.props.registro.chamada == 3 ? (
											<select
												className='form-control'
												disabled={item.presenca_semana3}
												value={item.id_motivo_semana3 || 0}
												onChange={(data, value) => {
													this.props.salvarChamada({ ...item, id_motivo_semana3: parseInt(data.target.value || 0) > 0 ? data.target.value : null });
												}}>
												<option value='0'>Selecione</option>
												{this.props.listaMotivo.map(opt => {
													return (
														<option key={opt.id} value={opt.id}>{opt.nome}</option>
													);
												})}
											</select>
										) : null}
										{this.props.registro.chamada == 4 ? (
											<select
												className='form-control'
												disabled={item.presenca_semana4}
												value={item.id_motivo_semana4 || 0}
												onChange={(data, value) => {
													this.props.salvarChamada({ ...item, id_motivo_semana4: parseInt(data.target.value || 0) > 0 ? data.target.value : null });
												}}>
												<option value='0'>Selecione</option>
												{this.props.listaMotivo.map(opt => {
													return (
														<option key={opt.id} value={opt.id}>{opt.nome}</option>
													);
												})}
											</select>
										) : null}
										{this.props.registro.chamada == 5 ? (
											<select
												className='form-control'
												disabled={item.presenca_semana5}
												value={item.id_motivo_semana5 || 0}
												onChange={(data, value) => {
													this.props.salvarChamada({ ...item, id_motivo_semana5: parseInt(data.target.value || 0) > 0 ? data.target.value : null });
												}}>
												<option value='0'>Selecione</option>
												{this.props.listaMotivo.map(opt => {
													return (
														<option key={opt.id} value={opt.id}>{opt.nome}</option>
													);
												})}
											</select>
										) : null}
									</Td>
								</Tr>
							))}
						</TBody>

					</Table>





				</ContentCardBody>
			</ContentCard>
		);
	}

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.funcionario.modoTela,
	lista: state.funcionario.lista,
	registro: state.turma.registro,
	listaMotivo: state.funcionario.listaMotivo,
	listaEmpresaFuncionario: state.funcionario.listaEmpresaFuncionario


});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, adicionar, salvar, salvarChamada, excluir, getLista, getListaMotivoFalta, setModoTelaTurma, getListaEmpresaFuncionario }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Funcionario);
